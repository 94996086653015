label.btn-primary.btn.active {
  background-color: white !important;
  color: #7727ff !important;
}

.btn {
  margin-top: 5px;
  margin-bottom: 5px;
}

.no-pointer, .btn-danger {
 cursor: default !important;
}

.btn-danger:hover {
  background-color: #F55252 !important;
  box-shadow: none !important;
}

.btn-dark:hover {
  background-color: #1A051D !important;
  box-shadow: none !important;
}
.selected{
  color: #f5eeee;
  background: #da7979;
  border-color: white;
}
.btn-selected{
  background: #FFFFFF;
  color: black;
}

.container {
  display: flex;
  flex-wrap: wrap;
}

.slot {
  width: 60%; /* Displays two slots per row on mobile */
  box-sizing: border-box;
  padding: 10px;
}

@media (min-width: 768px) {
  .slot {
    width: 25%; /* Displays four slots per row on larger screens */
  }
}

