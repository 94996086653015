.doctor-Img {
  width: 32px;
  height: 32px;
  border-radius: 40px;
  overflow: hidden;
}
.doctor-Img img {
  width: 100%;
  height: auto;
}
