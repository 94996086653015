@import "../node_modules/angular-calendar/css/angular-calendar.css";
@import '~quill/dist/quill.bubble.css';
@import '~quill/dist/quill.snow.css';
/* You can add global styles to this file, and also import other style files */

:root {
  --primary-color: #975AFF;
  --secondary-color: #616D89;
}

.sidebar-background {
  /*background-image: none !important;*/
  background-color: transparent !important;
}

.app-sidebar[data-background-color=man-of-steel] {
  /*background-image: none !important;*/
  /*background-repeat: no-repeat !important;*/
}

.app-sidebar[data-background-color=white] {
  background-color: transparent !important;
}

.app-sidebar[data-background-color=white] {
  background-color: transparent !important;
}

.ngx-datatable.material {
  box-shadow: none !important;
}

.no-right {
  right: 0 !important;
}

.app-sidebar .logo-img {
  width: 190px !important;
}


ngx-datatable.fixed-header .datatable-header .datatable-header-inner .datatable-header-cell{
  background-color:#d1c7c747;
  color:#605B5B;
}

.fullscreen-mode .app-sidebar.main-menu.menu-fixed.menu-native-scroll.expanded.ng-star-inserted {
  display: none !important;
}

.fullscreen-mode  app-navbar {
  display: none !important;
}

.fullscreen-mode .main-content {
  margin-left: 0px !important;
}

.secondary-color{
  background-color: var(--primary-color);
  color: #000000;
}

.primary-color {
  background-color: var(--secondary-color);
  color: #FFFFFF;
}
